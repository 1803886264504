.ngx-contextmenu {
    background-color: #fff;
    border: 1px solid #ccc;

    ul {
        list-style: none;
        padding: 0;
        margin: 0;

        li {

        }
    }
}
.ng-touch.ng-invalid,
.ng-submitted .ng-invalid {
	border-color: #f86c6b;
}

ng-select.ng-touch.ng-invalid .ng-select-container,
.ng-submitted ng-select.ng-invalid .ng-select-container {
	border-color: #f86c6b;
}

.ng-submitted timepicker.ng-invalid input {
	border-color: #f86c6b;
}

.ng-submitted app-timepicker.ng-invalid input {
	border-color: #f86c6b;
}

.ng-submitted app-duration.ng-invalid input {
	border-color: #f86c6b;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .worksheet-progress .ng-value-icon.left {
	border-right: 1px solid rgba(0, 0, 0, 0.3);
}

.ng-submitted app-utc-datepicker.ng-invalid input {
	border-color: #f86c6b;
}

.form-error {
	display: none;
	width: 100%;
	margin-top: 0.25em;
	color: #f86c6b;
}

.ng-submitted .form-error {
	display: block;
}

.form-warning {
	color: orange;
}

.errors {
	ul {
		padding: 0;
		margin: 0;
		list-style-type: none;
		color: #f86c6b;
	}
}

label {
	color: #5c6873;
}

.required-explain {
	color: red;

	&:before {
		content: "* ";
		color: red;
	}
}

label.required:after {
	color: red;
	content: " *";
}

th.required:after {
	color: red;
	content: " *";
}

.form-control-static {
	min-height: 21px;
}

.input-group-text {
	min-width: 50px;
	justify-content: center;
}

.btn {
	span {
		margin-left: 10px;
	}
}

.input-group.percentage {
	width: 120px;
}

input.number {
	width: 100px;
}

@media screen and (max-width: 960px) {
	.btn-with-icon {
		span {
			display: none;
		}
	}
}

.btn-left-margin {
	margin-left: 10px;
}

.ng-select .ng-select-container {
	border: 1px solid #e4e7ea;
}

.ng-select.ng-select-disabled {
	background-color: #e4e7ea;
	opacity: 1;

	& >.ng-select-container {
		background-color: #e4e7ea;
	}
}

bs-datepicker-inline .bs-datepicker {
	box-shadow: none;
}

.datetime-popup {
	z-index: 10000;
	& > div {
		display: flex;
		flex-direction: column;

		timepicker {
			padding: 15px;
			margin: auto;
		}

		.buttons {
			padding: 15px;
		}
	}
}

.input-group.date {
	width: 250px;
}


.input-group > ng-select {
	flex: 1 1 auto;
	width: 1%;
	position: relative;
	margin-bottom: 0;
}

.input-group.amount {
	width: 250px;
}

.drop-zone {
	border: 2px dashed #ccc;
	background-color: #eee;
	height: 50px;
}

.fileover {
	position: relative;
	* {
		pointer-events: none;
	}

	&:after {
		content: '';
		z-index: 1;
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		background: rgba(0, 0, 0, 0.2);
		border: 4px dashed #9e9e9e;
		pointer-events: none;
	}
}

.email-form {
	.attachments {
		ul {
			list-style-type: none;
			display: block;
			padding: 0;
			margin: 0;

			li {
				padding: 10px;
				border: 1px solid #ccc;
				border-radius: 5px;
				margin-right: 5px;
				margin-bottom: 5px;
				display: inline-flex;
				align-items: baseline;

				& > div {
					display: inline-block;

					& > span {
						display: block;
					}

					.size {
						color: #555;
						font-size: 14px;
					}
				}

				&.selected {
					background-color: #eee;
					border: 1px solod #555!important;
					box-shadow: none!important;
				}

				/*&:hover {
					background-color: #eee;
				}*/

				.remove {
					padding: 0;
				}
			}
		}

		.dts-select-box {
			background: rgba(174, 253, 207, 0.3);
			border: 2px solid #b1f0c7;
		}
	}
}
table.timesheet {
	td,
	th {
		border: 1px solid #ccc;
		position: relative;
		//padding: 0.75rem 0;
		padding: 0;
		height: 46px;
	}

	td.total-col {
		width: 50px;
		text-align: center;
		height: 46px;
		line-height: 46px;
		white-space: nowrap;
	}

	th.day,
	td.day {
		min-width: 43px;
		text-align: center;
		height: 46px;
	}

	th.day {
		& > div {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: space-evenly;
		}

		.warning {
			width: 10px;
			height: 10px;
			background: orange;
			border-radius: 5px;
		}
	}

	th.month {
		text-align: center;
		text-transform: capitalize;
	}

	th.total,
	td.total {
		text-align: center;
		height: 46px;
		min-width: 43px;
	}

	th.sep,
	td.sep {
		border-top: 2px solid #888;
	}

	th.label,
	td.label {
		padding-left: 0.75rem;
	}

	tr.extras td {
		background-color: #eee;
		text-align: center;
		line-height: 37px;
	}

	th > div,
	td > div {
		display: flex;
		flex-direction: column;
		text-align: center;
		height: 100%;
		justify-content: center;

		&.kms {
			border-bottom: 2px solid rgb(77 124 255);
		}

		&.adjusted-kms {
			border-bottom: 2px solid rgb(201, 121, 0);
		}

		span.linked-but-not-approved {
			font-style: italic;
			//opacity: 0.5;
			text-decoration: overline underline dashed;
			text-underline-offset: 2px;
		}

		span {
			line-height: 32px;
		}

		span.travel-hours {
			line-height: 6px;
			color: red;
		}

		span.night-hours {
			line-height: 6px;
			color: blue;
		}

		span.extra {
			line-height: 6px;
			color: rgb(36, 179, 0);
		}
	}

	th.public-holiday,
	td.public-holiday {
		background-color: #ffead7;
	}

	tbody td.empty:after {
		content: "";
		display: block;
		position: absolute;
		background-color: rgba(0, 0, 0, 0.05);
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
		-webkit-print-color-adjust: economy;
	}

	thead tr:last-child th {
		border-bottom: 2px solid #888;
	}

	tfoot tr:first-child td {
		border-top: 2px solid #888;
	}

	td.week-end,
	th.week-end,
	td.week-end {
		background-color: #ededed;
	}

	td.AA {
		background-color: #21e4b3 !important;
	}

	td.AT {
		background-color: #dabc17 !important;
	}

	td.AV {
		background-color: #fa5c12 !important;
	}

	td.CSS {
		background-color: #829df6 !important;
	}

	td.FM {
		background-color: #aed1ff !important;
	}

	td.FOF {
		background-color: #21e4b3 !important;
	}

	td.HW {
		background-color: #aad8a3 !important;
	}

	td.JF {
		background-color: #ffd0a5 !important;
	}

	td.M {
		background-color: #fa5c12 !important;
	}

	tbody td.P {
		background-color: #aad8a3 !important;
	}

	td.PAT {
		background-color: #21e4b3 !important;
	}

	td.PC {
		background-color: #21e4b3 !important;
	}

	td.RA {
		background-color: #21e4b3 !important;
	}

	td.RCR {
		background-color: #21e4b3 !important;
	}

	td.RF {
		background-color: #21e4b3 !important;
	}

	td.SPR {
		background-color: #ffc561 !important;
	}

	td.VA {
		background-color: #12a07d !important;
	}

	td.VAN {
		background-color: #e7aeee !important;
	}

	td.VC {
	}

	td.CE {
		background-color: #aed1ff;
	}

	td.CT {
		background-color: #aed1ff;
	}

	.extras {
		& > div {
			display: inline-block;
		}
	}
}

.timesheet-entry-status {
	margin-right: 10px;
	margin-bottom: 10px;
}

.legend {
	display: flex;

	& > div {
		flex: 1;
	}

	& > div:nth-child(2) {
		border-left: 1px solid #ccc;
		padding-left: 10px;
		margin-bottom: 10px;
	}

	h2 {
		color: #4dbd74;
		font-size: 18px;
	}

	& > div > div > div {
		display: inline-block;
		margin-right: 10px;
	}

	.different-hours {
		width: 10px;
		height: 10px;
		background: orange;
		border-radius: 5px;
	}

	.linked-but-not-approved {
		font-style: italic;
		//opacity: 0.5;
		text-decoration: overline underline dashed;
		text-underline-offset: 2px;
	}

	.night-hours {
		line-height: 6px;
		color: blue;
	}

	.travel-hours {
		line-height: 6px;
		color: red;
	}

	.kms {
		line-height: 6px;
		border-bottom: 2px solid rgb(77 124 255);
	}

	.adjusted-kms {
		line-height: 6px;
		border-bottom: 2px solid rgb(201, 121, 0);
	}
}

.same-day {
	color: #52ab45;
}
.card {
	margin-bottom: 1rem;
}

.card.bg-primary {
	background-color: #2f353a !important;
}

.card-header {
	display: flex;
	align-items: center;

	.title {
		flex: 1;
	}
}

.card-body {
	&.scrollable {
		overflow: auto;
	}
}

.card-button {
	height: 50px;
	text-align: right;

	.btn {
		margin-left: 5px;
	}

	i {
		margin-right: 5px;
	}
}
// Here you can add other styles
@import "card";
@import "button";
@import "forms";
@import "type";
@import "timeline";
@import "table";
@import "~@ng-select/ng-select/themes/default.theme.css";
@import "layout";
@import "status";
@import "timesheet";
@import "_context_menu";

body {
	position: relative;
}

.modal-open {
	overflow: hidden;
}

.modal-backdrop {
	z-index: 100000;

}

.modal {
	z-index: 100001;
}

bs-datepicker-container, bs-daterangepicker-container {
	z-index: 100002;
}

.datepicker-today {
	background-color: #ddd;
}

.toast-container {
	position: fixed;
}

.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
	height: 4px;
	background-color: theme-color("primary");
}

.mat-tab-link {
	&:hover {
		text-decoration: none;
		color: theme-color("primary");
	}
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
	background: theme-color("primary");
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
	background: theme-color("primary");
}

.mat-checkbox-background {
	background: transparent;
}

.mat-checkbox-inner-container mat-checkbox-persistent-ripple mat-ripple-element {
	background: theme-color("primary");
}

.tooltip-warning .tooltip-inner {
	background-color: #e89b26;
}

.tooltip-warning .tooltip-arrow::before {
	border-bottom-color: #e89b26;
}

.table-date-search {
	max-width: 100px;
}

/**
 * Context Menu
 */
.ngx-contextmenu .dropdown-menu {
	border: 1px solid #ccc;
	background-color: #fff;
	padding: 0;
	box-shadow: 3px 3px 3px 0px rgba(0, 0, 0, 0.3);

	li {
		border-bottom: 1px solid #ccc;

		a {
			color: #23282c;
			display: block;
			width: 100%;
			height: 100%;
			padding: 10px;

			&:hover {
				text-decoration: none;
			}
		}

		&:last-child {
			border-bottom: 0;
		}

		&:hover {
			background-color: #eee;
		}
	}
}

/*.sidebar .nav-link:hover {
	background-color: #4dbd74;
}

.sidebar .nav-link.active .nav-icon {
	color: #4dbd74;
}

.sidebar .nav-link:hover .nav-icon {
	color: #fff;
}*/

.index-item {
	padding: 30px;

	i {
		margin-right: 20px;
	}

	color: #23282c;
}

a.index-item {
	&:hover {
		background-color: #eee;
		color: theme-color("primary");
		text-decoration: none;
	}
}



@media screen and (max-width: 960px) {
	.btn-responsive {
		span {
			display: none;
		}
	}
}

.user-card, .worker-card {
	background-color: transparent;
	border: 0;
	padding: 0;
	min-width: 400px;
	font-size: 0.875rem;

	&.bs-popover-top > .arrow,
	&.bs-popover-auto[x-placement^="top"] > .arrow {
		bottom: calc((0.5rem + 0px) * -1);
	}

	.arrow {
		z-index: 1;
	}

	.popover-body {
		padding: 0;

		.card {
			margin-bottom: 0;
		}

		.card-body {
			/*padding: 10px;*/
		}
	}
}

@media screen and (max-width: 960px) {
	.user-card {
		min-width: 100%;
	}
}

.popover-card {
	background-color: transparent;
	border: 0;
	padding: 0;

	&.bs-popover-top > .arrow,
	&.bs-popover-auto[x-placement^="top"] > .arrow {
		bottom: calc((0.5rem + 0px) * -1);
	}

	.arrow {
		z-index: 1;
	}

	.popover-body {
		padding: 0;

		.card {
			margin-bottom: 0;
		}
	}
}

.day-detail-popover {
    width:fit-content;
    max-width: 1000px !important;
}

.day-detail {
    width:fit-content;
    max-width: 1200px !important;
	min-width: 500px;
}

.modal-content > .card {
	margin-bottom: 0;
}

/**
 *
 */

//@import "@coreui/coreui/scss/bootstrap/functions";

@import "./variables";

app-confirm-modal {
	.modal-title {
		padding: 20px;
		padding-bottom: 10px;
		text-align: center;

		span {
			padding-top: 10px;
			display: block;
		}
	}

	.modal-body {
		text-align: center;
		&.reason {
			text-align: left;
		}

	}

	.fa {
		font-size: 40px;
		color: theme-color("warning");
	}

	.modal-footer {
		background-color: theme-color("light"); // #f0f3f5
	}

	.error {
		margin: 0 20px 20px 20px;
	}
}

.modal-dialog {
	.modal-footer {
		background-color: theme-color("light"); // #f0f3f5
	}
}

.issue-modal {
	max-width: 800px;
}

.worker-availability-modal {
	max-width: 800px;
}


.modal-classic {
	h4 {
		padding: 1rem;
		background-color: #2f353a;
		color:#fff;

		i {
			margin-right: 10px;
		}
	}
}


.todo {
	color: theme-color("warning");
}

.cal-month-view .cal-open-day-events {
	div {
		padding: 8px;

		.btn-sm {
			padding: 6px 12px !important;
		}
	}

	.cal-event-title:hover {
		text-decoration: none !important;
	}
}

/**
 * Tables
 */
.mat-column-actions {
	.btn:not(:first-child) {
		margin-left: 5px;
	}
}

.mat-header-cell.center .mat-sort-header-container {
	justify-content: center;
}



.ng-dropdown-panel {
	z-index: 100005!important;
}

body > bs-dropdown-container {
	z-index: 10000;
}
.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
	background-color: theme-color("primary");
}

.mat-horizontal-stepper-header-container {
	// Put a line right after the last step
	&:after {
		content: '';
		border-top-color: rgba(0, 0, 0, 0.12);
		border-top-width: 1px;
		border-top-style: solid;
		flex: auto;
		height: 0;
		margin: 0 -16px;
		min-width: 32px;
	}
}

.table-filter {
	padding: 10px 10px 10px 0;
	display: block;
	min-height: 56px;
}

.table-filtered {
	margin-top: 10px;
	th {
		vertical-align: top !important;

		button {
			align-items: flex-start;
		}

		&.search-button {
			//vertical-align: bottom !important;
			//padding-bottom: 10px;
			padding-top: 28px;
			font-size: 18px;
		}
	}
}

.history-modal {
	max-width: 1300px;
	.modal-content {
		width: 1300px;
	}
}


/**
 * Select
 */
.select-tag {
	padding: 2px 5px;
	background-color: #eee;
	border: 1px solid #ddd;
	border-radius: 3px;
	margin-left: 5px;
}

.tag {
	border: 1px solid #ccc;
	background-color: #eee;
	padding: 3px 10px;
	border-radius: 7px;
	white-space: nowrap;
}

.map_search {
	position: absolute;
	z-index: 1000000;
	top: 10px;
	width: 300px;
	left: calc(50% - 150px);
}
.wrapper {
    width: 100%;
    @include ltr-rtl("padding-left", var(--cui-sidebar-occupy-start, 0));
    @include ltr-rtl("padding-right", var(--cui-sidebar-occupy-end, 0));
    will-change: auto;
    @include transition(padding 0.15s);
}

body {
    //overflow: hidden;
    font-family: Liberation Sans;
}

html, body {
    height: 100%;

    .ng {
        height: 100%;
    }
}

.bg-light {
    background-color: #e4e5e6;
}

.container-fluid {
    height: 100%;
}

/**
 * Menu
 */

.sidebar {
    --cui-nav-link-font-size: 0.875rem;
    --cui-sidebar-nav-title-margin-top: 0rem;
    --cui-sidebar-nav-icon-font-size: 0.875rem;
    --cui-sidebar-nav-icon-width: 2rem;

    .nav-icon {
        margin: 0 0.5rem 0 0;
    }
}

html:not([dir="rtl"]) .sidebar-nav .nav-icon:first-child {
    margin-left: calc(var(--cui-sidebar-nav-link-padding-x) * -1 + 1rem);
}

@media (min-width: 768px) {
    .sidebar.hide:not(.sidebar-end) ~ * {
        --cui-sidebar-occupy-start: 0px !important;
    }
}

.sidebar .disabled .nav-link {
    color: #777;
    cursor: default;
    font-size: 0.5rem;

    &:hover {
        background-color: transparent;

        .nav-icon {
            color: #777;
        }
    }
}

.container-lg {
    max-width: none;
}

/**
 * Footer
 */
footer.form-footer {
    background-color: #f0f3f5;
    border-top: 1px solid #ccc;
    position: fixed;
    bottom: 0px;
    left: 0;
    margin-left: 0;
    min-height: 50px;
    z-index: 10001;
    width: 100%;
    padding: 10px;
    text-align: right;
    transition:
        margin-left 0.25s,
        margin-right 0.25s,
        width 0.25s,
        flex 0.25s;

    .btn {
        margin-left: 10px;
    }
}

/*
.sidebar-minimized footer.form-footer {
    margin-left: 50px;
    width: calc(100% - 50px);
}
*/

footer.form-footer {
    margin-left: var(--cui-sidebar-occupy-start);
    width: calc(100% - var(--cui-sidebar-occupy-start));
}

/*
.aside-menu-lg-show footer.form-footer {
    margin-left: 0;
    width: calc(100% - 250px);
}
*/

@media (max-width: 991.98px) {
    .sidebar-lg-show footer.form-footer,
    footer.form-footer {
        margin-left: 0;
        width: 100%;
    }
}

.title-with-progress {
    h1 {
        margin-top: 0.25rem;
        margin-bottom: 0.25rem;
        margin-right: 0.5rem;
    }
}

.navbar-nav .user {
    i {
        margin-right: 10px;
    }

    .fullname {
        margin-right: 10px;
    }
}

/**
 * Header
 */
.dropdown-item {
    cursor: pointer;
    border-bottom: 1px solid #ccc;
    padding: 0.5rem 1rem;
}

.page-header {
    background-color: #e4e5e6;
    position: fixed;
    left: 0px;
    margin-left: 0;
    top: 100px;
    width: calc(100%);
    z-index: 1000;
    padding: 20px;
    margin-left: var(--cui-sidebar-occupy-start);
    padding-bottom: 0;
    transition:
        margin-left 0.25s,
        margin-right 0.25s,
        width 0.25s,
        flex 0.25s;

    .page-header-title {
        align-items: center;
        padding-bottom: 0.5rem !important;
    }

    .buttons {
        text-align: right;
    }
}

.sidebar-minimized .page-header {
    margin-left: 50px;
    width: calc(100% - 50px);
}

.sidebar-lg-show .page-header {
    margin-left: 200px;
    width: calc(100% - 200px);
}

.aside-menu-lg-show .page-header {
    margin-left: 0;
    width: calc(100% - 250px);
}

.sidebar-lg-show.aside-menu-lg-show .page-header {
    margin-left: 200px;
    width: calc(100% - 450px);
}

@media (max-width: 991.98px) {
    .sidebar-lg-show .page-header,
    .page-header {
        margin-left: 0;
        width: 100%;
        position: relative;
        top: 0;
    }
}

.page-content {
    padding-top: 111px;
}

.selection-header {
    background-color: #fff;
    position: absolute;
    width: 100%;
}

@media (max-width: 991.98px) {
    .page-content {
        padding-top: 0;
    }
}

@media screen and (max-width: 960px) {
    .main .container-fluid {
        padding: 5px 5px;
    }
}

.print-header {
    display: none;
}

@media print {
    /*@page {
        size: 700mm 500mm;
    }*/

    html,
    body {
        width: 100%;
    }

    .no-print,
    .no-print * {
        display: none !important;
    }

    .sidebar-lg-show.sidebar-fixed .main,
    .sidebar-lg-show.sidebar-fixed .app-footer,
    .sidebar-show.sidebar-fixed .main,
    .sidebar-show.sidebar-fixed .app-footer,
    .main {
        margin-left: 0 !important;
    }

    * {
        overflow: visible !important;
    }

    .main .container-fluid {
        padding: 0 0;
    }

    .breadcrumb-fixed .main {
        padding-top: 0;
    }

    .table-striped tbody tr:nth-of-type(odd) {
        -webkit-print-color-adjust: exact;
    }

    .print-header {
        width: 100%;
        display: block;
        -webkit-print-color-adjust: exact;
        overflow: hidden;
    }

    .card-header,
    .card-footer,
    .btn {
        -webkit-print-color-adjust: exact;
    }

    .table,
    .table tr,
    table td,
    table th {
        -webkit-print-color-adjust: exact !important;
    }

    td.day {
        -webkit-print-color-adjust: exact;
    }
}

/**
 * Quotations
 */
 .quotation-progress {
	border: 1px solid #ccc;
	border-radius: 5px;
	background-color: #eee;
	padding: 3px 10px;
	-webkit-print-color-adjust:exact;
	white-space: nowrap;

	&.TO_BE_ASSIGNED {
		background-color: #fff;
	}

	&.IN_PREPARATION {
		background-color: #aed1ff;
	}

	&.TO_BE_APPROVED_BY_BUM {
		background-color: #feebd5;
	}

	&.TO_BE_APPROVED_BY_MANAGEMENT {
		background-color: #feebd5;
	}

	&.READY_TO_BE_SENT {
		background-color: #e7aeee;
	}

	&.SENT {
		background-color: #ffc561;
	}

	&.ACCEPTED {
		background-color: #aad8a3;
	}

	&.CANCELLED_BY_JDC {
		background-color: #feaaaa;
	}

	&.REFUSED_BY_CUSTOMER {
		background-color: #ff8787;
	}
}

/**
 * Worksheets
 */
.worksheet-progress {
	border: 1px solid #ccc;
	border-radius: 5px;
	background-color: #eee;
	padding: 3px 10px;
	-webkit-print-color-adjust:exact;
	white-space: nowrap;

	&.IN_PREPARATION {
		background-color: #aed1ff;
	}

	&.IN_PROGRESS {
		background-color: #e7aeee;
	}

	&.FINISHED {
		background-color: #ffc561;
	}

	&.APPROVED {
		background-color: #aad8a3;
	}

	.ng-value-icon.left {
		border-right: 1px solid rgba(0, 0, 0, 0.3);
	}
}

/**
 * Timesheets
 */
.timesheet-progress {
	border: 1px solid #ccc;
	border-radius: 5px;
	background-color: #eee;
	padding: 3px 10px;
	display: inline-block;
	-webkit-print-color-adjust:exact;
	white-space: nowrap;

	&.IN_PREPARATION {
		background-color: #aed1ff;
	}

	&.FINISHED {
		background-color: #ffc561;
	}
}

/**
 * Timesheets Entry statuses
 */
 .timesheet-entry-status {
	border: 1px solid #ccc;
	border-radius: 5px;
	background-color: #eee;
	padding: 3px 10px;
	display: inline-block;
	-webkit-print-color-adjust:exact;
	white-space: nowrap;
}

/**
 * Project
 */
 .project-progress {
	border: 1px solid #ccc;
	border-radius: 5px;
	background-color: #eee;
	padding: 3px 10px;
	-webkit-print-color-adjust:exact;
	white-space: nowrap;

	&.IN_PROGRESS {
		background-color: #aad8a3;
	}

	&.WARRANTY {
		background-color: #feebd5;
	}

	&.FINISHED {
		background-color: rgb(77, 77, 77);
		color: #fff;
	}
}

/**
 * Project progress report progress
 */
 .project-progress-report-progress {
	border: 1px solid #ccc;
	border-radius: 5px;
	background-color: #eee;
	padding: 3px 10px;

	&.IN_PREPARATION {
		background-color: #aed1ff;
	}

	&.TO_BE_APPROVED_BY_BUM {
		background-color: #feebd5;
	}

	&.REJECTED_BY_BUM {
		background-color: #feaaaa;
	}

	&.READY_TO_BE_SENT {
		background-color: #e7aeee;
	}

	&.SENT {
		background-color: #ffc561;
	}

	&.FINISHED {
		background-color: #aad8a3;
	}
}

/**
 * Leave
 */
 .leave-progress {
	border: 1px solid #ccc;
	border-radius: 5px;
	background-color: #eee;
	padding: 3px 10px;
	-webkit-print-color-adjust:exact;
	white-space: nowrap;
	color: #000;

	&.ASKED {
		background-color: #aed1ff;
		border: 1px solid #71afff;
	}

	&.APPROVED {
		background-color: #aad8a3;
	}

	&.REJECTED {
		background-color: #fa5c12;
	}

	&.CANCELLED {
		background-color: #feebd5;
	}
}


// Custom Theming for Angular Material
@use '@angular/material' as mat;
@import '@angular/material/theming';
// For more information: https://material.angular.io/guide/theming
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-legacy-component-typographies();
@include mat.legacy-core();

$jdc-palette: (
 50: #e8eaf6,
 100: #c5cae9,
 200: #9fa8da,
 300: #7986cb,
 400: #7986cb,
 500: #61BE74,
 600: #7986cb,
 700: #7986cb,
 800: #7986cb,
 900: #61BE74,
 // ... continues to 900
 contrast: (
   50: rgba(black, 0.87),
   100: rgba(black, 0.87),
   200: rgba(black, 0.87),
   300: white,
   400: white,
   500: white,
   600: white,
   700: white,
   800: white,
   900: white,
   // ... continues to 900
 )
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$ng-primary: mat.define-palette($jdc-palette);
$ng-accent: mat.define-palette($jdc-palette);

// The warn palette is optional (defaults to red).
$ng-warn: mat.define-palette(mat.$red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$ng-theme: mat.define-light-theme($ng-primary, $ng-accent, $ng-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-legacy-component-themes($ng-theme);

@import "./liberation-sans.css";
@import "~angular-calendar/css/angular-calendar.css";


@import "variables";

$enable-ltr: true;
$enable-rtl: true;

// Import styles with default layout.
@import "@coreui/coreui/scss/coreui";

// Import Chart.js custom tooltips styles
@import "@coreui/chartjs/scss/coreui-chartjs";

// perfect scrollbar theming for use with [perfectScrollbar] directive
//@import '~perfect-scrollbar/css/perfect-scrollbar.css';

@import "layout";

// Some temp fixes
@import "fixes";

// Prism.js
//@import "examples";

// If you want to add something do it here
@import "custom";

@import "./icomoon.css";

@import './node_modules/@perfectmemory/ngx-contextmenu/src/assets/stylesheets/base.scss';
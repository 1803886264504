.timeline {
  list-style: none;
  padding: 20px 0 20px;
  position: relative;

  &:before {
    top: 0;
    bottom: 0;
    position: absolute;
    content: " ";
    width: 1px;
    background-color: #d4d4d4;
    left: 50%;
    margin-left: -1.5px;
  }

  & > li {
    margin-bottom: 20px;
    position: relative;

    & > .timeline-panel {
      width: 46%;
      float: left;
      border: 1px solid #d4d4d4;
      background-color: #fff;
      border-radius: 2px;
      padding: 20px;
      position: relative;
      -webkit-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.175);
      box-shadow: 0 1px 6px rgba(0, 0, 0, 0.175);

      &:after {
        position: absolute;
        top: 22px;
        right: -14px;
        display: inline-block;
        border-top: 14px solid transparent;
        border-left: 14px solid #fff;
        border-right: 0 solid #fff;
        border-bottom: 14px solid transparent;
        content: " ";
      }
    }

    & > .timeline-panel:before {
      position: absolute;
      top: 21px;
      right: -15px;
      display: inline-block;
      border-top: 15px solid transparent;
      border-left: 15px solid #ccc;
      border-right: 0 solid #ccc;
      border-bottom: 15px solid transparent;
      content: " ";
    }

    & > .timeline-badge {
      color: #fff;
      width: 40px;
      height: 40px;
      line-height: 40px;
      font-size: 1.2em;
      text-align: center;
      position: absolute;
      top: 16px;
      left: 50%;
      margin-left: -20px;
      background-color: #999999;
      z-index: 100;
      border-top-right-radius: 50%;
      border-top-left-radius: 50%;
      border-bottom-right-radius: 50%;
      border-bottom-left-radius: 50%;
    }

    &.timeline-inverted > .timeline-panel {
      float: right;

      &:before {
        border-left-width: 0;
        border-right-width: 15px;
        left: -15px;
        right: auto;
      }

      &:after {
        border-left-width: 0;
        border-right-width: 14px;
        left: -14px;
        right: auto;
      }
    }
  }

  & > li:before,
  & > li:after {
    content: " ";
    display: table;
  }

  & > li:after {
    clear: both;
  }

  & > li:before,
  & > li:after {
    content: " ";
    display: table;
  }

  & > li:after {
    clear: both;
  }
}

.timeline-badge.primary {
  background-color: #2e6da4 !important;
}

.timeline-badge.success {
  background-color: #3f903f !important;
}

.timeline-badge.warning {
  background-color: #f0ad4e !important;
}

.timeline-badge.danger {
  background-color: #d9534f !important;
}

.timeline-badge.info {
  background-color: #5bc0de !important;
}

.timeline-title {
  margin-top: 0;
  color: inherit;
}

.timeline-body > p,
.timeline-body > ul {
  margin-bottom: 0;
}

.timeline-body > p + p {
  margin-top: 5px;
}

.timeline-body {
  li {
    display: flex;

    .field {
      flex: 1;
      margin-right: 10px;
      font-weight: bold;
      text-align: right;
    }

    .value {
      flex: 1;
    }
  }
}

.history-modal {
  //width: 1240px;

  .modal-content {
    //width: 1000px;
  }
}

@media (max-width: 767px) {
  ul.timeline:before {
    left: 40px;
  }

  ul.timeline > li > .timeline-panel {
    width: calc(100% - 90px);
    width: -moz-calc(100% - 90px);
    width: -webkit-calc(100% - 90px);
  }

  ul.timeline > li > .timeline-badge {
    left: 15px;
    margin-left: 0;
    top: 16px;
  }

  ul.timeline > li > .timeline-panel {
    float: right;
  }

  ul.timeline > li > .timeline-panel:before {
    border-left-width: 0;
    border-right-width: 15px;
    left: -15px;
    right: auto;
  }

  ul.timeline > li > .timeline-panel:after {
    border-left-width: 0;
    border-right-width: 14px;
    left: -14px;
    right: auto;
  }
}

.btn-primary {
    background-color: #20a8d8;
    border-color: #20a8d8;
    --cui-btn-hover-bg: #3ea662;
    --cui-btn-hover-border: #3a9d5d;
    &.disabled,
    &:disabled {
        background-color: #297f9e;
        border-color: #21576b;
        color: #ddd;
    }
}

.btn-default {
    background-color: #fff;
    border-color: #ddd;
    color: #555;
    &:hover {
        background-color: #f5f5f5;
        border-color: #ddd;
        color: #555;
    }
}

.btn-danger {
    --cui-btn-color: #fff;
}

.btn-primary .warning {
    margin-right: 5px;
    color: #ff670b;
}

.btn {
    font-family: Liberation Sans;
    --cui-btn-color: #fff;
    --cui-btn-hover-color: #fff;
    --cui-btn-hover-bg: #3ea662;
    --cui-btn-hover-border-color: #3a9d5d;
}

.btn-warning {
    --cui-btn-hover-color: #23282c;
    --cui-btn-bg: #F5BE20;
    --cui-btn-hover-bg: #e5ac0a;
    --cui-btn-hover-border-color: #d8a30a;
}

.btn-warning {
    color: #464545;
}

.buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-bottom: 10px;
}
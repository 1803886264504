.mat-table .mat-cell.number {
  text-align: right;
  justify-content: flex-end;
}

@media screen and (max-width: 960px) {
  .mat-table {
    border: 0;
    display: block;

    tbody[role="rowgroup"] {
      display: block;
      width: 100%;
    }

    thead, tfoot {
      display: none;
    }
  }

  .mat-table caption {
    font-size: 1em;
  }

  .mat-table .mat-header-cell {
    border: 10px solid;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  .mat-table .mat-row {
    border-bottom: 5px solid #ddd;
    display: block;
    width: 100%;
    height: inherit;
  }
  /*
	  .mat-table .mat-row:nth-child(even) {background: #CCC}
	  .mat-table .mat-row:nth-child(odd) {background: #FFF}
	  */
  .mat-table .mat-cell {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: 1em;
    text-align: right;
    /*height: 30px;*/
    /*margin-bottom: 4%;*/
    padding: 3%;
    min-height: 40px;
  }
  .mat-table .mat-cell:before {
    /*
		* aria-label has no advantage, it won't be read inside a table
		content: attr(aria-label);
		*/
    content: attr(data-label);
    float: left;
    font-weight: bold;

    font-size: 0.85em;
  }
  .mat-table .mat-cell:last-child {
    border-bottom: 0;
  }
  .mat-table .mat-cell:first-child {
    /*margin-top: 4%;*/
  }



  th.mat-header-cell:last-of-type,
    td.mat-cell:last-of-type,
    td.mat-footer-cell:last-of-type {
        padding-right: 3%!important;
    }

    th.mat-header-cell:first-of-type,
    td.mat-cell:first-of-type,
    td.mat-footer-cell:first-of-type {
        padding-left: 3%!important;
    }
}

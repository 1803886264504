@font-face {
  font-family: 'icomoon';
  src:  url('../assets/fonts/icomoon.eot?t2wxh0');
  src:  url('../assets/fonts/icomoon.eot?t2wxh0#iefix') format('embedded-opentype'),
    url('../assets/fonts/icomoon.ttf?t2wxh0') format('truetype'),
    url('../assets/fonts/icomoon.woff?t2wxh0') format('woff'),
    url('../assets/fonts/icomoon.svg?t2wxh0#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-subcontractor:before {
  content: "\1f300";
}
